<template>
  <v-container
    fluid
    fill-height
    class="login-bg"
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md3
      >
        <v-form
          ref="loginForm"
          @submit.prevent="handleFormSubmit"
        >
          <v-card flat>
            <v-card-text class="logo">
              <img
                :src="appLogo"
                class="filter-blue"
              >
            </v-card-text>

            <v-card-text class="mb-5">
              Please sign-in to your account
            </v-card-text>

            <v-card-text
              v-if="errorMessages.length > 0"
            >
              <v-alert
                text
                color="error"
                rounded="0"
              >
                <small
                  v-for="(err, idx) in errorMessages"
                  :key="idx"
                  class="d-block mb-1"
                >
                  - <strong>{{ err.message }}</strong>
                </small>
              </v-alert>
            </v-card-text>

            <!-- login form -->
            <v-card-text>
              <v-text-field
                v-model="email"
                outlined
                label="USERNAME"
                placeholder="USERNAME"
                :error-messages="errorMessages.email"
                :rules="[validators.required, validators.emailValidator]"
                hide-details="auto"
                class="mb-6 rounded-0"
              ></v-text-field>

              <v-text-field
                v-model="password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="PASSWORD"
                :error-messages="errorMessages.password"
                placeholder="PASSWORD"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                :rules="[validators.required]"
                hide-details="auto"
                class="rounded-0"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-card-text>

            <v-card-text class="mt-5 mb-5">
              <VueRecaptcha
                ref="vueRecaptchaCtl"
                :sitekey="siteKey"
                :load-recaptcha-script="true"
                @verify="handleReCaptchaSucess"
                @error="handleReCaptchaError"
                @expired="handleExpired"
              ></VueRecaptcha>
            </v-card-text>

            <v-card-actions>
              <v-btn
                block
                color="primary"
                type="submit"
                class="mt-2 rounded-0"
                :loading="loading"
                :disabled="loading || reCaptchaToken == null"
              >
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import axios from '@axios'
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import {
  mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGithub, mdiGoogle, mdiTwitter,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import {
  getCurrentInstance, ref, computed, defineComponent,
} from '@vue/composition-api'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha,
  },
  setup() {
    // Template Ref
    const loginForm = ref(null)
    const siteKey = computed(() => process.env.VUE_APP_RECAPTCHA_SITEKEY)
    const reCaptchaToken = ref(null)
    const vueRecaptchaCtl = ref(null)
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const loading = ref(false)
    const isPasswordVisible = ref(false)

    const email = ref(null)
    const password = ref(null)
    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleReCaptchaError = () => {
      reCaptchaToken.value = null
    }

    const handleExpired = () => {
      reCaptchaToken.value = null
    }

    const handleReCaptchaSucess = response => {
      reCaptchaToken.value = response
    }

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      loading.value = true

      errorMessages.value = ref([])

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */
      axios
        .post('/userAccess/authentication', { username: email.value, password: password.value, token: reCaptchaToken.value })
        .then(response => {
          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          localStorage.setItem('accessToken', response.data.accessToken)

          return response
        })
        .then(() => {
          axios.get('/userAccess/authorization').then(response => {
            const user = response.data
            const { ability: userAbility } = user

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(userAbility)

            // Set user's ability in localStorage for Access Control
            localStorage.setItem('userAbility', JSON.stringify(userAbility))

            // We will store `userAbility` in localStorage separate from userData
            // Hence, we are just removing it from user object
            delete user.ability

            user.shopId = 'fff28640-6ecc-4c2b-98fa-afdea2a72609'

            // Set user's data in localStorage for UI/Other purpose
            localStorage.setItem('userData', JSON.stringify(user))

            // On success redirect to home
            router.push('/')
          })
        })
        .catch(error => {
          errorMessages.value = error.response.data.errors
          vueRecaptchaCtl.value.reset()
          reCaptchaToken.value = null
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      handleFormSubmit,
      handleReCaptchaError,
      handleReCaptchaSucess,
      handleExpired,
      siteKey,
      reCaptchaToken,
      vueRecaptchaCtl,
      isPasswordVisible,
      loading,
      email,
      password,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
